import axios from 'axios';
import {BASE_URL, REFRESH} from './allApis';

// Define your custom paramsSerializer function
const paramsSerializer = (params) => {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json' // Adding 'Accept' header for consistency
  },
  paramsSerializer: paramsSerializer,
  withCredentials: true // Whether to include cookies in CORS requests
});

export const axiosPubInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json' // Adding 'Accept' header for consistency
  },
  paramsSerializer: paramsSerializer,
  withCredentials: true // Whether to include cookies in CORS requests
});

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// let isRefreshing = false;
// Interceptor for handling errors globally
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },

//   async function (error) {
//     if (error.response) {
//       const originalRequest = error.config;
//       const refresh = localStorage.getItem('refresh');

//       if (
//         error.response?.status === 401 &&
//         !originalRequest._retry &&
//         refresh
//       ) {
//         if (!isRefreshing) {
//           isRefreshing = true;
//           originalRequest._retry = true;
//           try {
//             const res = await axiosInstance.post(REFRESH, {
//               refresh: refresh
//             });
//             setAuthToken(res.data?.access);
//             originalRequest.headers['Authorization'] =
//               'Bearer ' + res.data.access;
//             return axiosInstance(originalRequest);
//           } catch (error) {
//             console.log(error);
//             setAuthToken();
//           } finally {
//             isRefreshing = false;
//           }
//         }
//       } else if (error.request) {
//         // The request was made but no response was received
//         console.log('No response received:', error.request);
//       } else {
//         // Something happened in setting up the request that triggered an error
//         console.log('Error:', error.message);
//       }
//       // Return a rejected promise to propagate the error further
//       return Promise.reject(error);
//     }
//   }
// );

// Function to set authentication token in headers
export const setAuthToken = (access, refresh) => {
  if (access) {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + access;
    localStorage.setItem('access', access);
    if (refresh) {
      localStorage.setItem('refresh', refresh);
    }
  } else {
    // If token is falsy, remove the Authorization header
    delete axiosInstance.defaults.headers.common['Authorization'];
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
  }
};

export default axiosInstance;
