import React from 'react';
import PropTypes from 'prop-types';
import JWTAuthProvider from '../accounts/JWTAuthProvider';
import ServicesProvider from '../services/AllServicesProvider';
import PainterProvider from '../services/PainterProvider';
import TourGuideProvider from '../services/TourGuideProvider';
import OrderProvider from '../orders/OrderProvider';
import AddressProvider from '../address/AddressProvider';
import WorkerProvider from '../worker/WorkerProvider';
import ReviewProvider from '../reviews/ReviewProvider';
import ArticleProvider from '../articles/ArticleProvider';
import ThirdpartyAPIProvider from '../thirdparty/ThirdpartyAPIProvider';
import GlobalFormProvider from '../initialForm/GlobalFormProvider';
import MiscellaneousProvider from '../miscellaneous/MiscellaneousProvider';
import PackageProvider from '../packages/PackageProvider';
import PaymentProvider from '../payments/PaymentProvider';
import ChatProvider from '../chat/ChatProvider';

const AppAPIProvider = ({children}) => {
  return (
    <JWTAuthProvider>
      <ThirdpartyAPIProvider>
        <ChatProvider>
          <PainterProvider>
            <TourGuideProvider>
              <ArticleProvider>
                <ReviewProvider>
                  <AddressProvider>
                    <ServicesProvider>
                      <PackageProvider>
                        <WorkerProvider>
                          <MiscellaneousProvider>
                            <PaymentProvider>
                              <GlobalFormProvider>
                                <OrderProvider>{children}</OrderProvider>
                              </GlobalFormProvider>
                            </PaymentProvider>
                          </MiscellaneousProvider>
                        </WorkerProvider>
                      </PackageProvider>
                    </ServicesProvider>
                  </AddressProvider>
                </ReviewProvider>
              </ArticleProvider>
            </TourGuideProvider>
          </PainterProvider>
        </ChatProvider>
      </ThirdpartyAPIProvider>
    </JWTAuthProvider>
  );
};

export default AppAPIProvider;

AppAPIProvider.propTypes = {
  children: PropTypes.node.isRequired
};
