const ENV = process.env.REACT_APP_ENV;

const environments = {
  dev: 'http://localhost:8000',
  staging: 'https://myservice.dev',
  default: 'https://api.myservice.services'
};

const sockets = {
  dev: 'ws:localhost:8000',
  staging: 'wss:myservice.dev',
  default: 'wss:api.myservice.services'
};

export const BASE_URL = environments[ENV] || 'https://api.myservice.services';
export const SOCKET_URL = sockets[ENV] || 'ws:api.myservice.services';

// Accounts
export const SIGNIN_USER = '/accounts/login/';
export const REFRESH = '/accounts/refresh/';
export const GOOGLE_OAUTH = '/accounts/google/';
export const NEW_USER_REGISTER = 'accounts/register/';
export const SIGNUP_ACCOUNT = '/auth/users/';

export const USER_ME = '/auth/users/me/';
export const ACTIVATE = '/auth/users/activation/';
export const SET_PASSWORD = '/auth/users/set_password/';
export const RESET_PASSWORD = '/auth/users/reset_password/';
export const RESET_PASSWORD_CONFIRM = '/auth/users/reset_password_confirm/';

// services
export const AVAILABLE_SERVICES = 'services/service/';
export const SEARCH_SERVICES = 'services/search/';

// painting
export const PAINTER_QUERY = 'services/painting-query/';
export const PAINTER_BIDS = 'services/painting-bidding/';

// Tour Guide
export const TOUR_GUIDE_QUERY = 'services/tour-guide-query/';

// bids
export const BIDS = 'bids/';

// services
export const GET_ALL_PAINTING_QUERY = 'services/painting-query/';

//address
export const GET_ALL_ADDRESS = 'address/';

// order
export const ORDER = 'orders/order/';
export const HIRE = 'orders/hire/';

// Worker
export const CURRENT_WORKER = 'workers/current-worker/';
export const WORKER = 'workers/';
export const SWITCH_ACCOUNT_TO_SERVICE = 'workers/switch-account-to-service/';

// worker schedule
export const AVAILABLE_DAYS_TIME = 'workers/available-days/';
export const AVAILABLILITY = 'workers/availability/';

// Stone Types
export const CREATE_STONE_TYPE = 'workers/create-stone-type/';
export const UPDATE_STONE_TYPE = 'workers/update-stone-type/';

// Ceiling Types
export const CREATE_CEILING_TYPE = 'workers/create-ceiling-type/';
export const UPDATE_CEILING_TYPE = 'workers/update-ceiling-type/';

// Cheif
export const CREATE_DISH_TYPE = 'workers/create-offering-dish/';
export const UPDATE_DISH_TYPE = 'workers/update-offering-dish/';

// School
export const SCHOOL = 'workers/education/';

// reviews
export const REVIEWS = 'reviews/';
export const WORKER_REVIEWS = 'reviews/worker/';

// testimonies
export const TESTIMONIES = 'reviews/testimonies/';
export const WORKER_TESTIMONIES = 'reviews/worker/testimonies/';

// Articles
export const ARTICLE = 'articles/';

// miscellaneous

export const EDGES = 'workers/edgecuts/';
export const STONES = 'workers/available-stones/';
export const CEILING = 'workers/available-ceilings/';
export const DISHES = 'workers/available-dish/';

// Third party
export const GEOAPIFY = '8b75f49ac7f643ab8e0b9b0261e464e1';
export const GEOAPI_URL = 'https://api.geoapify.com/v1/geocode/';

export const UNSPLASH_API_KEY = 'AyWOvm4IPNaxiv-AjSYb1isWz_rrprG8GScvMY4D9ZQ';
export const UNSPLASH_URL = 'https://api.unsplash.com/search/photos';

// packages
export const PAINTER_PACKAGE = 'workers/painter-packages/';
export const WORKER_STONE = 'workers/stones-installer-offers/';
export const WORKER_OFFERING_DISHS = 'workers/chef-offering-dishes/';
export const WORKER_CEILING = 'workers/ceilings-installer-offers/';
export const TOUR_GUIDE_PACKAGE = 'workers/tour-guide-packages/';

// payfast payments;
export const PAYFAST_ACCESS_TOKEN = 'payfast/get-access-token/';
export const PAYFAST_INITIATE_TRANSACTION = 'payfast/initiate-transaction/';
export const PAYFAST_SUBMIT_FORM =
  'https://ipguat.apps.net.pk/Ecommerce/api/Transaction/PostTransaction';

// chats
export const CREATE_PRIVATE_ROOM = 'communicate/rooms/create-private-chat/';
export const CREATECSCHAT = 'communicate/rooms/create-customer-support-chat/';
export const USER_PRIVATE_CHATS = 'communicate/rooms/user-private-rooms/';
export const USER_CS_ROOM = 'communicate/rooms/user-customer-service-rooms/';
export const ALL_CS_ROOMs = 'communicate/rooms/customer-service/';
export const SEARCH_CHAT = 'communicate/rooms/search-chats';
export const AVAILABLE_WORKERs = 'communicate/rooms/available-workers/';
export const AVAILABLE_CHAT_USERs = 'communicate/rooms/available-users/';

// FEATURE PROFILE
export const FEATURE_TOUR_GUIDE = 'workers/features/travels-tours-agency/';
export const FEATURE_PAINTERS = 'workers/features/painters/';
export const FEATURE_PACKAGES = 'workers/tour-guide-packages/featured/';
export const GET_ALL_BUSSINESS = 'workers/features/all-bussiness/';

/// hotel, resort & rooms
export const ADD_NEW_ROOM = 'workers/hotel-room/';
